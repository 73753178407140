var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "mb": "28px"
    }
  }, [_c('c-box', {
    staticClass: "container",
    attrs: {
      "background": "#FFFFFF",
      "border": "1px solid #F2F2F2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "py": "25px",
      "px": "27px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "flex-grow": "1"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "600",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Demographic Analytic ")])], 1)], 1), _c('c-box', [_c('c-flex', [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&:hover': {
          background: '#F2F2F2',
          borderRadius: '0'
        },
        '&:focus, &:active': {
          color: 'primary.400',
          background: 'white',
          borderRadius: '0',
          boxShadow: 'none'
        }
      },
      expression: "{\n            '&:hover': {\n              background: '#F2F2F2',\n              borderRadius: '0',\n            },\n            '&:focus, &:active': {\n              color: 'primary.400',\n              background: 'white',\n              borderRadius: '0',\n              boxShadow: 'none',\n            },\n          }"
    }],
    attrs: {
      "size": "md",
      "height": "58px",
      "flex-grow": "1",
      "color": !_vm.type ? 'primary.400' : 'darkGray.900',
      "font-size": "16px",
      "line-height": "24px",
      "border-radius": "0",
      "background": !_vm.type ? 'white' : 'superLightGray.900',
      "border-color": "green.500"
    },
    on: {
      "click": function click($event) {
        return _vm.onClientType(null);
      }
    }
  }, [_vm._v(" Type Client ")]), _c('c-divider', {
    attrs: {
      "m": "0",
      "border-color": "lightGray",
      "orientation": "vertical"
    }
  }), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&:hover': {
          background: '#F2F2F2',
          borderRadius: '0'
        },
        '&:focus, &:active': {
          color: 'primary.400',
          background: 'white',
          borderRadius: '0',
          boxShadow: 'none'
        }
      },
      expression: "{\n            '&:hover': {\n              background: '#F2F2F2',\n              borderRadius: '0',\n            },\n            '&:focus, &:active': {\n              color: 'primary.400',\n              background: 'white',\n              borderRadius: '0',\n              boxShadow: 'none',\n            },\n          }"
    }],
    attrs: {
      "size": "md",
      "height": "58px",
      "flex-grow": "1",
      "color": _vm.type ? 'primary.400' : 'darkGray.900',
      "font-size": "16px",
      "line-height": "24px",
      "border-radius": "0",
      "background": _vm.type ? 'white' : 'superLightGray.900',
      "border-color": "green.500"
    },
    on: {
      "click": function click($event) {
        return _vm.onClientType('paying');
      }
    }
  }, [_vm._v(" Type Paying Clients ")])], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": 'repeat(' + _vm.headings.length + ', 0fr)'
    }
  }, _vm._l(_vm.headings, function (item, index) {
    return _c('c-box', {
      key: 'heading-' + index,
      attrs: {
        "w": item.width,
        "text-align": index === 0 || index === 4 || index === 5 ? 'center' : '',
        "py": "11px",
        "px": "14px",
        "color": "primary.400",
        "font-weight": "bold",
        "font-size": "12px",
        "line-height": "18px",
        "text-transform": "uppercase",
        "bg": "rgba(0, 140, 129, 0.1)",
        "display": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _vm._l(_vm.items, function (item, idx) {
    return _c('c-grid', {
      key: idx,
      attrs: {
        "w": "100%",
        "max-w": "100%",
        "template-columns": 'repeat(' + _vm.headings.length + ', 0fr)',
        "border-bottom": "1px solid #C4C4C4"
      }
    }, [_c('c-box', {
      attrs: {
        "w": _vm.headings[0].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.numberingRow(idx)) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[1].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.country ? item.country : "-") + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[2].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.province ? item.province : "-") + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[3].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.city ? item.city : "-") + " ")]), _vm.headings[4] ? _c('c-box', {
      attrs: {
        "w": _vm.headings[4].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.gender ? item.gender : "-") + " ")]) : _vm._e(), _vm.headings[5] ? _c('c-box', {
      attrs: {
        "w": _vm.headings[5].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.age ? item.age : "-") + " ")]) : _vm._e(), _vm.headings[6] ? _c('c-box', {
      attrs: {
        "w": _vm.headings[6].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.job ? item.job : "-") + " ")]) : _vm._e(), _vm.headings[7] ? _c('c-box', {
      attrs: {
        "w": _vm.headings[7].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.lastEducation ? item.lastEducation : "-") + " ")]) : _vm._e()], 1);
  }), !_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "py": "28px",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px"
    }
  }, [_vm._v(" Show: ")]), _c('c-select', {
    attrs: {
      "min-w": "120px",
      "border-radius": "6px",
      "font-size": "14px",
      "ml": "13px",
      "size": "md"
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "w": "100%"
    }
  }, [_c('Pagination', {
    attrs: {
      "total-pages": _vm.totalPages,
      "per-page": parseInt(_vm.perPage),
      "current-page": _vm.currentPage,
      "max-visible-buttons": 3
    },
    on: {
      "pagechanged": _vm.onChangePage
    }
  })], 1)], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }