<template>
  <c-flex
    justify-content="center"
    align="center"
  >
    <c-button
      v-if="currentPage > 3"
      py="0"
      px="14px"
      mr="8px"
      h="32px"
      min-w="32px"
      variant="outline"
      border-radius="30px"
      background-color="transparent"
      font-size="14px"
      font-weight="normal"
      @click="onClickFirstPage"
    >
      First
    </c-button>

    <c-button
      p="0"
      mr="16px"
      w="32px"
      h="32px"
      min-w="32px"
      variant="outline"
      border-radius="30px"
      background-color="transparent"
      :disabled="isInFirstPage"
      @click="onClickPreviousPage"
    >
      <c-icon
        name="chevron-left"
        size="24px"
      />
    </c-button>

    <c-button
      v-for="page in pages"
      :key="page"
      v-chakra="{
        ':hover': {
          bg: 'rgba(0, 140, 129, 0.3)',
          color: 'primary.400',
        },
      }"
      p="0"
      m="0"
      w="32px"
      h="32px"
      min-w="32px"
      mx="4px"
      border-radius="30px"
      font-weight="normal"
      :background-color="
        isPageActive(page) ? 'rgba(0, 140, 129, 0.3)' : 'transparent'
      "
      color="primary.400"
      :class="{
        last: page == totalPages && Math.abs(page - currentPage) > 2,
      }"
      @click="onClickPage(page)"
    >
      {{ page }}
    </c-button>
    <c-button
      p="0"
      ml="16px"
      w="32px"
      h="32px"
      min-w="32px"
      variant="outline"
      border-radius="30px"
      background-color="transparent"
      :disabled="isInLastPage"
      @click="onClickNextPage"
    >
      <c-icon
        name="chevron-right"
        size="24px"
      />
    </c-button>
  </c-flex>
</template>
<script>
export default {
  name: 'PaginationComp',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons
      }

      // When inbetween
      return this.currentPage - 1
    },
    pages() {
      const range = []

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        if (i < this.totalPages && i > 0) {
          range.push(i)
        }
      }
      range.push(this.totalPages)
      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    },
  },
}
</script>
<style>
.last {
  margin-left: 32px;
}
.last::before {
  content: "...";
  left: -25px;
  position: absolute;
}
</style>
