<template>
  <c-box
    flex-grow="1"
    class="container"
  >
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Dashboard
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex
      mb="33px"
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Dashboard
      </c-heading>
    </c-flex>

    <ClientTracking />
    <NutritionistTracking />
    <PaymentTracking />
    <CouponTracking />
    <DemographicAnalytic />
  </c-box>
</template>

<script>
import ClientTracking from './client-tracking.vue'
import NutritionistTracking from './nutritionist-tracking.vue'
import PaymentTracking from './payment-tracking.vue'
import CouponTracking from './coupon-tracking.vue'
import DemographicAnalytic from './demographic-analytic.vue'

export default {
  name: 'SuperadminPage',  
  components: {
    ClientTracking,
    NutritionistTracking,
    PaymentTracking,
    CouponTracking,
    DemographicAnalytic,
  },
  data() {
    return {}
  },
}
</script>
